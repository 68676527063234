import { Box, Card, Container, styled, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ASSETS } from 'src/assets';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

// const icons = {
//   MedicalKit: '/static/images/logo/medical-kit.svg',
//   FirebaseAuth: '/static/images/logo/firebase.svg',
//   JWT: '/static/images/logo/jwt.svg',
//   Amplify: '/static/images/logo/amplify.svg'
// };

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  padding: 0 0 0 440px;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(-60deg, ${theme.colors.secondary.main}AA,  ${theme.colors.secondary.main}00, #FFFFFF), url(${ASSETS.BG_LOGIN}), no-repeat top;
  background-size: cover; 
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

// const CardImg = styled(Card)(
//   ({ theme }) => `
//     border-radius: 100%;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     border: 1px solid ${theme.colors.alpha.black[10]};
//     transition: ${theme.transitions.create(['border'])};
//     position: absolute;

//     &:hover {
//       border-color: ${theme.colors.primary.main};
//     }
// `
// );

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

function LoginCover() {
  const { method } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Connexion - CNPS</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Logo />
              <Box mt={6}>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 7
                  }}
                >
                  {t('Caisse Nationale de prévoyance social')}
                </TypographyH1>
                {/* <Box
                  sx={{
                    position: 'relative',
                    width: 300,
                    height: 120
                  }}
                >
                  <Tooltip arrow placement="top" title="Auth0">
                    <CardImg
                      sx={{
                        width: 80,
                        height: 80,
                        left: -20,
                        top: -40
                      }}
                    >
                      <img width={40} alt="Auth0" src={icons.MedicalKit} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Firebase">
                    <CardImg
                      sx={{
                        width: 90,
                        height: 90,
                        left: 70
                      }}
                    >
                      <img width={50} alt="Firebase" src={icons.FirebaseAuth} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="JSON Web Token">
                    <CardImg
                      sx={{
                        width: 110,
                        height: 110,
                        top: -30,
                        left: 170
                      }}
                    >
                      <img width={80} alt="JSON Web Token" src={icons.JWT} />
                    </CardImg>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="AWS Amplify">
                    <CardImg
                      sx={{
                        width: 70,
                        height: 70,
                        bottom: 0,
                        right: -55
                      }}
                    >
                      <img width={50} alt="Amplify" src={icons.Amplify} />
                    </CardImg>
                  </Tooltip>
                </Box> */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    my: 3
                  }}
                >
                  {t(
                    'Gérez, Organisez et planifiez les visites médicales des employés grâce au nouveau portail des visites médicales'
                  )}
                </Typography>
                {/* <Typography
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Want to switch auth methods?')}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    'It only takes seconds. There is a documentation section showing how to do exactly that'
                  )}
                  .{' '}
                  <Link component={RouterLink} to="/docs">
                    Read docs
                  </Link>
                </Typography> */}
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4,
                background: '#FFFFFFCC'
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  fontFamily="Versus"
                  textTransform="uppercase"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Authentification')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t(
                    'Remplissez les champs ci-dessous pour accéder à votre compte'
                  )}
                </Typography>
              </Box>
              {/* {method === 'Auth0' && <Auth0Login />} */}
              {/* {method === 'FirebaseAuth' && <FirebaseAuthLogin />} */}
              {method === 'JWT' && <JWTLogin />}
              {/* {method === 'Amplify' && <AmplifyLogin />} */}
              {/* <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Link component={RouterLink} to="/account/register">
                  <b>Sign up here</b>
                </Link>
              </Box> */}
              {/* {method !== 'Auth0' && (
                <Tooltip
                  title={t('Used only for the live preview demonstration !')}
                >
                  <Alert severity="warning">
                    Use <b>demo@cnps.ci</b> and password <b>TokyoPass1@</b>
                  </Alert>
                </Tooltip>
              )} */}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
