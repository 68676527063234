import { LoadingButton } from '@mui/lab';
import { CircularProgress, TextField } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import * as Yup from 'yup';

const LoginJWT = () => {
  const { login } = useAuth();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submitLoginForm, setSubmitLoginForm] = useState(false);

  const handleLogin = async (values, actions) => {
    setSubmitLoginForm(true);
    let data = {
      login_user: values.email,
      password_user: values.password
    };

    try {
      const response = await login(data);
      if (response.status === 'erreur') {
        enqueueSnackbar(response.message, { variant: 'error' });
        setSubmitLoginForm(false);
      }
      // if (isMountedRef.current) {
      //   setStatus({ success: true });
      //   setSubmitting(false);
      // }
    } catch (err) {
      console.error(err);
      if (isMountedRef.current) {
        actions.setStatus({ success: false });
        actions.setErrors({ submit: err.message });
        setSubmitLoginForm(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          // .email(t('Veuillez saisir une adresse email valide'))
          .max(255)
          .required(t('Le login est obligatoire')),
        password: Yup.string()
          .max(255)
          .required(t('Le mot de passe est obligatoire'))
      })}
      onSubmit={async (values, actions) => {
        handleLogin(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('Adresse Email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Mot de passe')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {/* <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            />
            <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Lost password?')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )} */}

          <LoadingButton
            sx={{
              mt: 3
            }}
            color="primary"
            disabled={submitLoginForm}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            loading={submitLoginForm}
            loadingIndicator={<CircularProgress size="1rem" />}
            loadingPosition="center"
          >
            {t('Connexion')}
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
