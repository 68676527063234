import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { LISTE_PRESTATAIRES_URL } from 'src/config/endpoints';

export const prestatairesApi = createApi({
  reducerPath: 'prestatairesApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getListePrestataires: builder.query({
      query: () => ({
        url: LISTE_PRESTATAIRES_URL,
        method: 'GET'
      })
    })
  })
});

export const { useGetListePrestatairesQuery } = prestatairesApi;
