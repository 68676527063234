import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Authenticated from 'src/components/Authenticated';
import Guest from 'src/components/Guest';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
// import BaseLayout from 'src/layouts/BaseLayout';
// import accountRoutes from './account';
import applicationsRoutes from './applications';
// import accountRoutes from './account';
// import baseRoutes from './base';
import blocksRoutes from './blocks';
import dashboardsRoutes from './dashboards';
import managementRoutes from './management';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const HealthcareHospital = Loader(
  lazy(() => import('src/content/dashboards/Healthcare/HospitalView'))
);

const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const Adherants = Loader(lazy(() => import('src/content/pages/App/Adherants')));
const Campagnes = Loader(lazy(() => import('src/content/pages/App/Campagnes')));
const Candidats = Loader(lazy(() => import('src/content/pages/App/Candidats')));
const ImporterAdherants = Loader(lazy(() => import('src/content/pages/App/Candidats')));
const Profils = Loader(lazy(() => import('src/content/pages/App/Candidats')));
const Parametres = Loader(lazy(() => import('src/content/pages/App/Candidats')));
const DossierCampagne = Loader(lazy(() => import('src/content/pages/App/DossierCampagne')));

const router = [
  {
    path: '/',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  {
    path: '*',
    children: [
      {
        element: (
          <Authenticated>
            <AccentSidebarLayout />
          </Authenticated>
        ),
        children: [
          {
            path: 'tableau-de-bord',
            element: <HealthcareHospital />
          },
          {
            path: 'importer-adherants',
            element: <ImporterAdherants />
          },
          {
            path: 'adherants',
            children: [
              {
                path: '/',
                element: <Navigate to="liste-adherants" replace />
              },
              {
                path: 'liste-adherants',
                element: <Adherants />
              },
            ]
          },
          {
            path: 'campagnes',
            children: [
              {
                path: '/',
                element: <Navigate to="liste-campagnes" replace />
              },
              {
                path: 'liste-campagnes',
                element: <Campagnes />
              },
              {
                path: 'details-campagne/:libelle',
                element: <DossierCampagne />
              },
            ]
          },
          {
            path: 'profils',
            children: [
              {
                path: '/',
                element: <Profils />
              },
            ]
          },
          {
            path: 'candidats',
            children: [
              {
                path: '/',
                element: <Candidats />
              },
            ]
          },
          {
            path: 'parametres',
            children: [
              {
                path: '/',
                element: <Parametres />
              },
            ]
          },
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: (
          <BaseLayout>
            <Status404 />
          </BaseLayout>
        )
      }
    ]
  },
  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  }
];

export default router;
