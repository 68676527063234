import axios from 'axios';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  LISTE_PRESTATIONS_URL,
  LISTE_PRESTATIONS_CAMPAGNE_URL
} from 'src/config/endpoints';

export const getListePrestations = async () => {
  try {
    const response = await axios.get(LISTE_PRESTATIONS_URL);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const getListePrestationsParCampagne = async (campagneId) => {
  const parsedCampagneId = parseInt(campagneId);
  try {
    const response = await axios.get(
      `${LISTE_PRESTATIONS_CAMPAGNE_URL}?campagne_id=${parsedCampagneId}`
    );
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const prestationsApi = createApi({
  reducerPath: 'prestationsApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getListePrestation: builder.query({
      query: () => ({
        url: LISTE_PRESTATIONS_URL,
        method: 'GET'
      })
    }),
    getListePrestationsParCampagne: builder.query({
      query: (campagneId) => ({
        url: `${LISTE_PRESTATIONS_CAMPAGNE_URL}?campagne_id=${parseInt(
          campagneId
        )}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetListePrestationQuery,
  useGetListePrestationsParCampagneQuery
} = prestationsApi;
