import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { LISTE_TYPES_VISITE_URL } from 'src/config/endpoints';

export const typesVisiteApi = createApi({
  reducerPath: 'typesVisiteApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getListeTypesVisites: builder.query({
      query: () => ({
        url: LISTE_TYPES_VISITE_URL,
        method: 'GET'
      })
    })
  })
});

export const { useGetListeTypesVisitesQuery } = typesVisiteApi;
