import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { ASSETS } from 'src/assets';
import { LOGIN_PATH } from 'src/config/paths';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to={LOGIN_PATH}>
      <img alt="Logo cnps" src={ASSETS.LOGO_CNPS_MAIN} />
      {/* <Image src={ASSETS.LOGO_CNPS_MAIN} showLoading width={110} /> */}
      {/* <LogoSignWrapper>
        <LogoSign>
          <LogoSignInner />
        </LogoSign>
      </LogoSignWrapper>
      <Box
        component="span"
        sx={{
          display: { xs: 'none', sm: 'inline-block' }
        }}
      >
        <LogoTextWrapper>
          <Tooltip title={`${t('Version')} 3.0`} arrow placement="right">
            <VersionBadge>3.0</VersionBadge>
          </Tooltip>
          <LogoText>Tokyo</LogoText>
        </LogoTextWrapper>
      </Box> */}
    </LogoWrapper>
  );
}

export default Logo;
