import { apiConfig } from 'src/config';

export const API_BASE_URL = apiConfig.api_base_url;
export const LOGIN_URL = `${apiConfig.api_base_url}user/login`;
export const LISTE_ADHERANTS_URL = `${apiConfig.api_base_url}user/liste-des-adherants`;
export const LISTE_ADHERANTS_PAR_CAMPAGNE_URL = `${apiConfig.api_base_url}user/liste-des-adherant-campagnes`;
export const LISTE_ADHERANTS_PAR_CAMPAGNE_PRESTATAIRE_URL = `${apiConfig.api_base_url}user/liste-adherant-de-campagne`;
export const LISTE_CAMPAGNES_URL = `${apiConfig.api_base_url}user/liste-des-campagnes`;
export const LISTE_CAMPAGNES_PRESTATAIRE_URL = `${apiConfig.api_base_url}user/liste-campagne-prestataire`;
export const CREER_CAMPAGNE_URL = `${apiConfig.api_base_url}user/creer-nouvelle-campagne`;
export const SOUMETTRE_CAMPAGNE_URL = `${apiConfig.api_base_url}user/soumettre-campagne`;
export const CLOTURER_CAMPAGNE_URL = `${apiConfig.api_base_url}user/cloturer-campagne-chez-prestataire`;
export const SOUMETTRE_CAMPAGNE_PRESTATAIRE_URL = `${apiConfig.api_base_url}user/soumettre-campagne-prestataire`;
export const AJOUTER_PRESTATIONS_CAMPAGNE_URL = `${apiConfig.api_base_url}user/ajouter-prestations-a-campagne`;
export const AJOUTER_PRESTATION_PANIER_URL = `${apiConfig.api_base_url}user/ajouter-une-prestations-a-campagne`;
export const RETIRER_PRESTATION_PANIER_URL = `${apiConfig.api_base_url}user/retirer-une-prestations-a-campagne`;
export const AJOUTER_ADHERANT_CAMPAGNE_URL = `${apiConfig.api_base_url}user/ajouter-adherant-a-campagne`;
export const RETIRER_ADHERANT_CAMPAGNE_URL = `${apiConfig.api_base_url}user/retirer-adherant-campagne`;
export const LISTE_PRESTATIONS_CAMPAGNE_URL = `${apiConfig.api_base_url}user/liste-prestations-de-campagne`;
export const LISTE_PRESTATIONS_URL = `${apiConfig.api_base_url}parametres/liste-prestations`;
export const LISTE_TYPES_VISITE_URL = `${apiConfig.api_base_url}parametres/liste-type-visite-medicale`;
export const LISTE_PRESTATAIRES_URL = `${apiConfig.api_base_url}parametres/liste-prestataire-medicale`;
export const ENVOYER_SMS_ADHERANT_URL = `${apiConfig.api_base_url}user/envoyer-sms-adherant`; 
export const ENVOYER_MAIL_ADHERANT_URL = `${apiConfig.api_base_url}user/envoyer-mail-adherant`;
export const ACCUSER_RECEPTION_CAMPAGNE_URL = `${apiConfig.api_base_url}user/accuser-reception-campagne-service-med`; // Pour le service médical
export const MODFIIER_DATE_FIN_CAMPAGNE_URL = `${apiConfig.api_base_url}user/modifier-date-fin-campagne-service-medical`; // Pour le service médical

export const EDITER_CONSTANTES_VISITE_URL = `${apiConfig.api_base_url}user/mettre-a-jour-constantes-adherant`;
export const UPDATE_VISITE_ADHERANT_URL = `${apiConfig.api_base_url}user/mettre-a-jour-ligne-visite-adherant`;
export const ENREGISTRER_DOCUMENT_VISITE_URL = `${apiConfig.api_base_url}user/enregistrer-fichier-resultat`;
export const ENREGISTRER_DOCUMENT_RESULTAT_PRESTATION_URL = `${apiConfig.api_base_url}user/mettre-a-jour-resultat-prestation-visite`;
export const UPLOADER_DOCUMENT_VISITE_URL = `${apiConfig.api_base_url}user/uploader-fichier-resultat`;
export const LISTE_DOCUMENTS_VISITE_URL = `${apiConfig.api_base_url}user/liste-fichier-resultat`;
export const LISTE_PRESTATIONS_VISITE_URL = `${apiConfig.api_base_url}user/liste-resultat-prestation-visite-adherant`;
export const INFOS_VISITE_ADHERANT_URL = `${apiConfig.api_base_url}user/selectionner-ligne-visite-adherant`;
export const SOUMETTRE_RESULTAT_VISITE_URL = `${apiConfig.api_base_url}user/soumettre-resultat`;
export const SOUMETTRE_RESULTAT_PRESTATION_VISITE_URL = `${apiConfig.api_base_url}user/soumettre-resultat-prestation-visite`;

export const DISPLAY_DOCUMENT_VISITE_URL = `${apiConfig.api_base_storage}fichier/resultat_visite/`;
