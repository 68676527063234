import JwtDecode from 'jwt-decode';
import moment from 'moment';
/* eslint-disable no-bitwise */

export const JWT_SECRET = 'cnps_medical_cabinet';
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const sign = (payload, privateKey, header) => {
  const now = new Date();
  header.expiresIn = new Date(now.getTime() + header.expiresIn);
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(
          item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
        )
      )
      .join('')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const signUserData = (payload) => {
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(
          item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
        )
      )
      .join('')
  );

  return `${encodedPayload}.${signature}`;
};

export const decodeUserData = (userToken) => {
  const [encodedPayload, signature] = userToken.split('.');
  const payload = JSON.parse(atob(encodedPayload));

  // console.log(payload);

  const verifiedSignature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(
          item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
        )
      )
      .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('Invalid signature');
  }

  return payload;
};

export const decode = (token) => {
  // Ajouter signature
  const [encodedHeader, encodedPayload] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  // const verifiedSignature = btoa(
  //   Array.from(encodedPayload)
  //     .map((item, key) =>
  //       String.fromCharCode(
  //         item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
  //       )
  //     )
  //     .join('')
  // );

  // if (verifiedSignature !== signature) {
  //   throw new Error('Invalid signature');
  // }

  return payload;
};

export const verify = (token) => {
  // const [encodedHeader, encodedPayload, signature] = token.split('.');
  // const header = encodedHeader;
  // const payload = encodedPayload;
  // const header = JwtDecode(token, { header: true });
  const decodedToken = JwtDecode(token);
  // const decodedUserToken = userToken && decodeUserData(userToken);

  // console.log(decodedUserToken);

  const now = new Date();
  const expires = moment(decodedToken.exp * 1000);
  // console.log(`Actuellement: ${now}`);
  // console.log(`Expires: ${expires}`);

  if (now > expires) {
    throw new Error('The token is expired!');
  }

  // const verifiedSignature = btoa(
  //   Array.from(encodedPayload)
  //     .map((item, key) =>
  //       String.fromCharCode(
  //         item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
  //       )
  //     )
  //     .join('')
  // );

  // if (verifiedSignature !== signature) {
  //   throw new Error('The signature is invalid!');
  // }

  return true;
};
