import { combineReducers } from '@reduxjs/toolkit';
import { adherantsApi } from 'src/services/adherant';
// import { reducer as calendarReducer } from 'src/slices/calendar';
// import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
// import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { campagnesApi } from 'src/services/campagne';
import { prestatairesApi } from 'src/services/prestataire';
import { prestationsApi } from 'src/services/prestation';
import { typesVisiteApi } from 'src/services/type-visite';
import { visitesApi } from 'src/services/visite';

const rootReducer = combineReducers({
  [prestationsApi.reducerPath]: prestationsApi.reducer,
  [typesVisiteApi.reducerPath]: typesVisiteApi.reducer,
  [campagnesApi.reducerPath]: campagnesApi.reducer,
  [adherantsApi.reducerPath]: adherantsApi.reducer,
  [prestatairesApi.reducerPath]: prestatairesApi.reducer,
  [visitesApi.reducerPath]: visitesApi.reducer,
  // calendar: calendarReducer,
  // projectsBoard: projectsBoardReducer,
  // mailbox: mailboxReducer
});

export default rootReducer;
