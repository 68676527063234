import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { adherantsApi } from 'src/services/adherant';
import { campagnesApi } from 'src/services/campagne';
import { prestatairesApi } from 'src/services/prestataire';
import { prestationsApi } from 'src/services/prestation';
import { typesVisiteApi } from 'src/services/type-visite';
import { visitesApi } from 'src/services/visite';
import rootReducer from './rootReducer';

const rootMiddleware = [
  campagnesApi.middleware,
  typesVisiteApi.middleware,
  adherantsApi.middleware,
  prestationsApi.middleware,
  prestatairesApi.middleware,
  visitesApi.middleware
];
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(rootMiddleware)
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
