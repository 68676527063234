import axios from 'axios';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { LOGIN_URL } from 'src/config/endpoints';
import { decodeUserData, signUserData, verify } from 'src/utils/jwt';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken, userToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('usrToken', userToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('usrToken');
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
  // register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const userToken = window.localStorage.getItem('usrToken');

        if (accessToken && verify(accessToken)) {
          if (userToken) {
            setSession(accessToken, userToken);
            const user = decodeUserData(userToken);

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (data) => {
    const response = await axios.post(LOGIN_URL, data);
    if (response.data.status === 'succes') {
      const accessToken = response.data.accessToken;
      const userToken = signUserData(response.data.resultat);
      // const accessToken = sign(response.data.resultat, JWT_SECRET, JWT_EXPIRES_IN)
      const user = response.data.resultat;
      setSession(accessToken, userToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }

    return response.data;

    // setSession(accessToken);
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user
    //   }
    // });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  // const register = async (email, name, password) => {
  //   const response = await axios.post('/api/account/register', {
  //     email,
  //     name,
  //     password
  //   });
  //   const { accessToken, user } = response.data;

  //   window.localStorage.setItem('accessToken', accessToken);
  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user
  //     }
  //   });
  // };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
        // register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
