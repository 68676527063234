import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import axios from 'axios';
import {
  EDITER_CONSTANTES_VISITE_URL,
  ENREGISTRER_DOCUMENT_RESULTAT_PRESTATION_URL,
  ENREGISTRER_DOCUMENT_VISITE_URL,
  INFOS_VISITE_ADHERANT_URL,
  LISTE_DOCUMENTS_VISITE_URL,
  LISTE_PRESTATIONS_VISITE_URL,
  SOUMETTRE_RESULTAT_PRESTATION_VISITE_URL,
  SOUMETTRE_RESULTAT_VISITE_URL,
  UPDATE_VISITE_ADHERANT_URL,
  UPLOADER_DOCUMENT_VISITE_URL
} from 'src/config/endpoints';

export const updateConstantesVisite = async (data) => {
  try {
    const response = await axios.post(EDITER_CONSTANTES_VISITE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const updateInfosVisiteAdherant = async (data) => {
  try {
    const response = await axios.post(UPDATE_VISITE_ADHERANT_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};
export const soumettreResultatLignePrestation = async (data) => {
  try {
    const response = await axios.post(SOUMETTRE_RESULTAT_PRESTATION_VISITE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const soumettreVisite = async (data) => {
  try {
    const response = await axios.post(SOUMETTRE_RESULTAT_VISITE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const uploadDocumentVisite = async (data) => {
  let requestResponse;
  let fichierData = {
    libelle_resultat: data.libelle_resultat,
    visite_id: data.visite_id
  };
  let fd = new FormData();
  fd.append('fichier', data.fichier_resultat, data.fichier_resultat.name);
  await axios.post(UPLOADER_DOCUMENT_VISITE_URL, fd).then(
    async (uploadRes) => {
      const uploadResponse = await uploadRes.data;
      fichierData = {
        ...fichierData,
        fichier_resultat: uploadResponse.resultat
      };

      // Enregistrement Fichier
      const registerResponse = await axios.post(
        ENREGISTRER_DOCUMENT_VISITE_URL,
        fichierData
      );
      requestResponse = registerResponse.data;
    },
    (error) => {
      requestResponse = { statut: 'erreur', message: error };
    }
  );

  return requestResponse;
};

export const uploadDocumentResultatPrestationVisite = async (data) => {
  let requestResponse;
  let fichierData = {
    reponse: data.observations,
    resultat_prest_id: data.prestation_id
  };
  let fd = new FormData();
  fd.append('fichier', data.fichier_resultat, data.fichier_resultat.name);
  await axios.post(UPLOADER_DOCUMENT_VISITE_URL, fd).then(
    async (uploadRes) => {
      const uploadResponse = await uploadRes.data;
      fichierData = {
        ...fichierData,
        fichier: uploadResponse.resultat
      };

      // Enregistrement Fichier
      const registerResponse = await axios.post(
        ENREGISTRER_DOCUMENT_RESULTAT_PRESTATION_URL,
        fichierData
      );
      requestResponse = registerResponse.data;
    },
    (error) => {
      requestResponse = { statut: 'erreur', message: error };
    }
  );

  return requestResponse;
};

export const visitesApi = createApi({
  reducerPath: 'visitesApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getInfosVisiteAdherant: builder.query({
      query: (visiteId) => ({
        url: `${INFOS_VISITE_ADHERANT_URL}?visite_id=${parseInt(visiteId)}`,
        method: 'GET'
      })
    }),
    getDocumentsVisiteAdherant: builder.query({
      query: (visiteId) => ({
        url: `${LISTE_DOCUMENTS_VISITE_URL}?visite_id=${parseInt(visiteId)}`,
        method: 'GET'
      })
    }),
    getPrestationsVisiteAdherant: builder.query({
      query: (visiteId) => ({
        url: `${LISTE_PRESTATIONS_VISITE_URL}?visite_id=${parseInt(visiteId)}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetInfosVisiteAdherantQuery,
  useGetDocumentsVisiteAdherantQuery,
  useGetPrestationsVisiteAdherantQuery
} = visitesApi;
