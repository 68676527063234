import axios from 'axios';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  CREER_CAMPAGNE_URL,
  LISTE_CAMPAGNES_URL,
  AJOUTER_ADHERANT_CAMPAGNE_URL,
  AJOUTER_PRESTATIONS_CAMPAGNE_URL,
  SOUMETTRE_CAMPAGNE_URL,
  SOUMETTRE_CAMPAGNE_PRESTATAIRE_URL,
  RETIRER_ADHERANT_CAMPAGNE_URL,
  AJOUTER_PRESTATION_PANIER_URL,
  RETIRER_PRESTATION_PANIER_URL,
  LISTE_CAMPAGNES_PRESTATAIRE_URL,
  ENVOYER_MAIL_ADHERANT_URL,
  ENVOYER_SMS_ADHERANT_URL,
  ACCUSER_RECEPTION_CAMPAGNE_URL,
  MODFIIER_DATE_FIN_CAMPAGNE_URL,
  CLOTURER_CAMPAGNE_URL
} from 'src/config/endpoints';

export const getListeCampagnes = async () => {
  try {
    const response = await axios.get(LISTE_CAMPAGNES_URL);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const creerCampagne = async (data) => {
  try {
    const response = await axios.post(CREER_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const soumettreCampagne = async (data) => {
  try {
    const response = await axios.post(SOUMETTRE_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const soumettreCampagnePrestataire = async (data) => {
  try {
    const response = await axios.post(SOUMETTRE_CAMPAGNE_PRESTATAIRE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const cloturerCampagne = async (data) => {
  try {
    const response = await axios.post(CLOTURER_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const retirerAdherantCampagne = async (data) => {
  try {
    const response = await axios.post(RETIRER_ADHERANT_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const ajouterAdherantsCampagne = async (data) => {
  try {
    const response = await axios.post(AJOUTER_ADHERANT_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const ajouterPrestationsCampagne = async (data) => {
  try {
    const response = await axios.post(AJOUTER_PRESTATIONS_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const ajouterPrestationPanier = async (data) => {
  try {
    const response = await axios.post(AJOUTER_PRESTATION_PANIER_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const retirerPrestationPanier = async (data) => {
  try {
    const response = await axios.post(RETIRER_PRESTATION_PANIER_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const envoyerSmsAdherant = async (data) => {
  try {
    const response = await axios.post(ENVOYER_SMS_ADHERANT_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const marquerCampagneRecu = async (data) => {
  try {
    const response = await axios.post(ACCUSER_RECEPTION_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const envoyerMailAdherant = async (data) => {
  try {
    const response = await axios.post(ENVOYER_MAIL_ADHERANT_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const modifierDateFinCampagne = async (data) => {
  try {
    const response = await axios.post(MODFIIER_DATE_FIN_CAMPAGNE_URL, data);
    return response.data;
  } catch (error) {
    return { statut: 'erreur', message: error };
  }
};

export const campagnesApi = createApi({
  reducerPath: 'campagnesApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    }
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getListeCampagnes: builder.query({
      query: () => ({
        url: LISTE_CAMPAGNES_URL,
        method: 'GET'
      })
    }),
    getListeCampagnesPrestataire: builder.query({
      query: (prestataireId) => ({
        url: `${LISTE_CAMPAGNES_PRESTATAIRE_URL}?prestataire_id=${parseInt(prestataireId)}`,
        method: 'GET'
      })
    }),
    // getListeCampagneParPartenaire: builder.mutation({
    //   query: (campagneId) => ({
    //     url: `${LISTE_PRESTATIONS_CAMPAGNE_URL}?campagne_id=${parseInt(
    //       campagneId
    //     )}`,
    //     method: 'GET'
    //   })
    // }),
    creerCampagne: builder.mutation({
      query: (data) => ({
        url: CREER_CAMPAGNE_URL,
        method: 'POST',
        body: data
      })
    }),
    soumettreCampagne: builder.mutation({
      query: (data) => ({
        url: SOUMETTRE_CAMPAGNE_URL,
        method: 'POST',
        body: data
      })
    }),
    ajouterAdherantCampagne: builder.mutation({
      query: (data) => ({
        url: AJOUTER_ADHERANT_CAMPAGNE_URL,
        method: 'POST',
        body: data
      })
    }),
    ajouterPrestationsCampagne: builder.mutation({
      query: (data) => ({
        url: AJOUTER_PRESTATIONS_CAMPAGNE_URL,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useGetListeCampagnesQuery,
  useGetListeCampagnesPrestataireQuery,
  // useGetListeCampagnesPartenairesM,
  useCreerCampagneMutation,
  useSoumettreCampagneMutation,
  useAjouterAdherantCampagneMutation,
  useAjouterPrestationsCampagneMutation
} = campagnesApi;
